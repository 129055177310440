<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable persistent max-width="1100px">
      <v-card class="sellers-actions-container">
        <v-card-title class="d-flex justify-content-between h-75px">
          <div>
            <span class="font-weight-medium m-4 text-h5"
              >{{ this.$t(actionType) }} SKU</span
            >
          </div>
          <button @click="toggleModal">
            <span class="svg-icon mr-2">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </button>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-card class="p-5 sellers-tab-card">
              <v-tabs
                :slider-size="2"
                show-arrows
                color="#99A1B7"
                align-with-title
                v-model="activeTab"
                slider-color="#9843c4"
              >
                <v-tabs-slider color="#9843c4"></v-tabs-slider>
                <!-- Tab header starts -->
                <template v-for="(tab, index) in tabs">
                  <v-tab
                    :key="index"
                    :disabled="tab.disabled"
                    class="poppins"
                    @change="onTabChange(tab.key)"
                  >
                    <span class="svg-icon mr-2">
                      <v-icon size="22">mdi-{{ tab.icon }}</v-icon>
                    </span>
                    {{ tab.title }}
                  </v-tab>
                </template>
                <!-- Tab header ends -->
                <!-- Tab content starts -->

                <v-tab-item>
                  <v-card flat class="d-flex flex-column">
                    <div
                      class="mt-6 d-flex flex-column flex-fill custom-tab-container"
                    >
                      <!-- Main fields starts -->
                      <MainFormFields
                        ref="main"
                        :serverData="serverData.main"
                        :pageLoader="pageLoader"
                        :refresher="refresher"
                        :actionItem="actionItem"
                      />
                      <!-- Main fields ends -->
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card flat class="d-flex flex-column">
                      <div
                        class="d-flex flex-column flex-fill custom-tab-container"
                      >
                        <!-- Attributes fields starts -->
                        <AttributesFormFields
                          ref="attributes"
                          tabName="attributes"
                          :pageLoader="pageLoader"
                          :refresher="refresher"
                          :actionItem="actionItem"
                        />
                        <!-- Attributes fields ends -->
                      </div>
                    </v-card>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card flat class="d-flex flex-column">
                      <div
                        class="d-flex flex-column flex-fill custom-tab-container"
                      >
                        <!-- Control fields starts -->
                        <ControlFormFields
                          ref="control"
                          tabName="control"
                          :pageLoader="pageLoader"
                          :refresher="refresher"
                          :actionItem="actionItem"
                        />
                        <!-- Control fields ends -->
                      </div>
                    </v-card>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card flat class="d-flex flex-column">
                      <div
                        class="d-flex flex-column flex-fill custom-tab-container"
                      >
                        <!-- Additional fields starts -->
                        <AdditionalFormFields
                          ref="additional"
                          tabName="additional"
                          :pageLoader="pageLoader"
                          :refresher="refresher"
                          :actionItem="actionItem"
                        />
                        <!-- Additional fields ends -->
                      </div>
                    </v-card>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card flat class="d-flex flex-column">
                      <div
                        class="d-flex flex-column flex-fill custom-tab-container"
                      >
                        <!-- Additional fields starts -->
                        <div class="pa-4 w-100">
                          <SkuDatatable
                            ref="codes"
                            tabName="codes"
                            :pageLoader="pageLoader"
                            :refresher="refresher"
                            :actionItem="actionItem"
                          />
                        </div>
                        <!-- Additional fields ends -->
                      </div>
                    </v-card>
                  </v-card>
                </v-tab-item>
                <!-- Tab content ends -->
              </v-tabs>
            </v-card>
          </v-container>
        </v-card-text>
        <!--        <v-card-actions>-->
        <!--          <v-spacer></v-spacer>-->
        <!--          &lt;!&ndash; <v-btn color="blue darken-1" text @click="dialog = false">-->
        <!--            Close-->
        <!--          </v-btn>-->
        <!--          <v-btn color="blue darken-1" text @click="dialog = false">-->
        <!--            Save-->
        <!--          </v-btn> &ndash;&gt;-->
        <!--        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ApiService from "@/core/services/api.service";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import MainFormFields from "@/own/components/fulfillment/skus/forms/MainFormFields.vue";
import AttributesFormFields from "@/own/components/fulfillment/skus/forms/AttributesFormFields.vue";
import ControlFormFields from "@/own/components/fulfillment/skus/forms/ControlFormFields.vue";
import AdditionalFormFields from "@/own/components/fulfillment/skus/forms/AdditionalFormFields.vue";
import SkuDatatable from "@/own/components/fulfillment/skus/forms/SkuDatatable.vue";

export default {
  name: "ActionsDialog",
  props: ["refresher"],
  components: {
    SkuDatatable,
    MainFormFields,
    AttributesFormFields,
    ControlFormFields,
    AdditionalFormFields,
  },
  data: () => ({
    dialog: false,
    serverData: {
      main: null,
    },
    activeTab: 0,
    actionType: "",
  }),
  methods: {
    toggleModal() {
      if (!this.dialog) {
        if (!this.actionItem) {
          this.loadServerData("create").then(() => {
            this.activeTab = 0;
            this.dialog = true;
            this.actionType = "Create";
          });
        } else {
          this.loadServerData("edit").then(() => {
            this.activeTab = 0;
            this.dialog = true;
            this.actionType = "Edit";
          });
        }
      } else {
        this.$store.commit(SET_ITEM_FOR_ACTION, null);
        this.dialog = false;
        this.reset();
      }
    },
    loadServerData(slug) {
      this.pageLoader(true);
      let payload = {
        tab_name: "main",
      };

      if (this.actionItem) {
        payload.id = this.actionItem.id;
      }

      return ApiService.post(`/fulfillment/skus/${slug}`, payload)
        .then(({ data }) => {
          this.serverData.main = data;
        })
        .then(() => {
          if (payload.tab_name === "main" && slug === "create") {
            setTimeout(() => {
              this.$refs.main.getClients();
            }, 50);
          }

          if (this.actionItem) {
            setTimeout(() => {
              this.$refs.main.getFormData();
            }, 50);
          }
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    onTabChange(tab) {
      // console.log(tab);
      setTimeout(() => {
        if (tab === "Attributes") {
          this.$refs.attributes.loadServerData();
        } else if (tab === "Control") {
          this.$refs.control.loadServerData();
        } else if (tab === "Additional") {
          this.$refs.additional.loadServerData();
        } else if (tab === "Alternative_SKUs") {
          this.$refs.codes.loadServerData();
        }
      });
    },
    pageLoader(val = true) {
      this.$store.commit(SET_PAGE_LOADING, val);
    },
    reset() {
      this.serverData = {
        main: {},
      };

      //Reset forms validations
      this.$refs.main.reset();
      if (this.$refs["attributes"]) this.$refs.attributes.reset();
      if (this.$refs["control"]) this.$refs.control.reset();
      if (this.$refs["additional"]) this.$refs.additional.reset();
    },
    // refresher() {
    //   this.$store.dispatch(UPDATE_TABLE_DATA, this.tableState);
    // },
  },
  computed: {
    actionItem: function() {
      return this.$store.getters.getItemForAction;
    },
    tabs: function() {
      return [
        {
          // title: this.$t("main"),
          title: "Main",
          key: "Main",
          icon: "home",
          disabled: false,
        },
        {
          title: "Attributes",
          key: "Attributes",
          icon: "dresser", // toolbox
          disabled: !this.actionItem,
        },
        {
          title: "Control",
          key: "Control",
          icon: "security",
          disabled: !this.actionItem,
        },
        {
          title: "Additional",
          key: "Additional",
          icon: "tab-plus",
          disabled: !this.actionItem,
        },
        {
          title: "Alternative SKUs",
          key: "Alternative_SKUs",
          icon: "toolbox",
          disabled: !this.actionItem,
        },
      ];
    },
    tableState: function() {
      return this.$store.getters.getCLIENTTableState;
    },
  },
};
</script>

<style lang="scss">
.sellers-tab-card {
  border: 1px solid #f1f1f4 !important;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03) !important;
  // min-height: 560px;
}

.no-scrollbar {
  scrollbar-width: none;
}

.sellers-actions-container {
  .v-card__text {
    overflow: hidden !important;
  }
}

.custom-tab-container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 300px) !important;
}
</style>
