<template>
  <v-container class="d-flex flex-column flex-fill ml-2 mr-4">
    <v-row v-if="serverData">
      <v-col cols="12" lg="8">
        <FieldContainer label="Storage info">
          <v-text-field
            v-model="formData.storage_info"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Handling info" :divider="false">
          <v-text-field
            v-model="formData.handling_info"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" lg="8" class="pr-0">
        <v-row class="d-flex justify-content-end">
          <v-col cols="auto">
            <button
              @click="submit"
              type="submit"
              class="btn btn-info px-5 py-3"
            >
              {{ this.$t("submit") }}
            </button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { required, requiredIf } from "vuelidate/lib/validators";

import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";

// import validationMixin from "@/own/mixins/validationMixin.js";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import FieldContainer from "@/own/components/fulfillment/skus/forms/FieldContainer.vue";

export default {
  name: "AdditionalFormFields",
  components: { FieldContainer },
  props: ["pageLoader", "actionItem", "refresher", "tabName"],
  // validations: {
  //   formData: {
  //     organization_id: {
  //       required: requiredIf(function() {
  //         return !!this.serverData.organizations;
  //       }),
  //     },
  //     email: { required },
  //   },
  // },
  data: () => ({
    formData: {
      storage_info: null,
      handling_info: null,
    },
    serverData: null,
  }),
  // created() {
  //   this.loadServerData();
  // },
  methods: {
    loadServerData() {
      // if (!this.serverData) {
      this.pageLoader(true);
      let payload = {
        tab_name: this.tabName,
      };

      if (this.actionItem) {
        payload.id = this.actionItem.id;
      }

      return ApiService.post("/fulfillment/skus/edit", payload)
        .then(({ data }) => {
          if (!this.serverData) {
            this.serverData = data;
          }

          Object.entries(data?.sku).forEach(([key, value]) => {
            this.formData[key] = value;
          });
        })
        .finally(() => {
          this.pageLoader(false);
        });
      // }
    },
    submit() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // }
      this.pageLoader(true);

      let url = `/fulfillment/skus/${this.actionItem ? `update` : `store`}`;

      const payload = {
        tab_name: this.tabName,
        id: this.actionItem.id,
        ...this.formData,
      };

      ApiService.post(url, payload)
        .then(({ data }) => {
          if (data.sku) {
            this.$store.commit(SET_ITEM_FOR_ACTION, data.sku);
          }
          const title = this.actionItem
            ? this.$t("updated")
            : this.$t("created");
          SwalService.successMessage({
            title: title,
            html: this.$t("submitted_successfully"),
          });
          this.refresher();
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    reset() {
      // this.$v.$reset();
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = null;
      });
    },
  },
  computed: {
    // organization_idErrors() {
    //   return this.handleFormValidation("organization_id", this);
    // },
  },
};
</script>
