<template>
  <v-container class="d-flex flex-column flex-fill ml-2 mr-4">
    <v-row v-if="serverData">
      <v-col cols="12" lg="8">
        <FieldContainer :label="$t('brand')">
          <v-autocomplete
            v-model="formData.brand_id"
            class="rounded-lg h-45px"
            :items="serverData.brands"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Sub brand">
          <v-text-field
            v-model="formData.sub_brand"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer :label="$t('category')">
          <v-autocomplete
            v-model="formData.category_id"
            class="rounded-lg h-45px"
            :items="serverData.categories"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer :label="$t('sku_sub_category')">
          <v-autocomplete
            v-model="formData.sub_category_id"
            class="rounded-lg h-45px"
            :items="filteredSubCategories"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
            :loading="isLoading"
          />
        </FieldContainer>

        <FieldContainer :label="$t('size')">
          <v-text-field
            v-model="formData.size"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer :label="$t('color')">
          <v-text-field
            v-model="formData.color"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Gender">
          <v-autocomplete
            v-model="formData.gender_id"
            class="rounded-lg h-45px"
            :items="serverData.genders"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer :label="$t('design_number')">
          <v-text-field
            v-model="formData.design_number"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer :label="$t('hs_code')">
          <v-text-field
            v-model="formData.hs_code"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Hs description">
          <v-text-field
            v-model="formData.hs_description"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer :label="$t('country')">
          <v-autocomplete
            v-model="formData.country_id"
            class="rounded-lg h-45px"
            :items="serverData.countries"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Cycle class" :divider="false">
          <v-autocomplete
            v-model="formData.cycle_class_id"
            class="rounded-lg h-45px"
            :items="serverData.cycle_classes"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" lg="8" class="pr-0">
        <v-row class="d-flex justify-content-end">
          <v-col cols="auto">
            <button
              @click="submit"
              type="submit"
              class="btn btn-info px-5 py-3"
            >
              {{ this.$t("submit") }}
            </button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { required, requiredIf } from "vuelidate/lib/validators";

import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";

// import validationMixin from "@/own/mixins/validationMixin.js";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import FieldContainer from "@/own/components/fulfillment/skus/forms/FieldContainer.vue";

export default {
  name: "AttributesFormFields",
  components: { FieldContainer },
  props: ["pageLoader", "actionItem", "refresher", "tabName"],
  // validations: {
  //   formData: {
  //     organization_id: {
  //       required: requiredIf(function() {
  //         return !!this.serverData.organizations;
  //       }),
  //     },
  //     email: { required },
  //   },
  // },
  data: () => ({
    formData: {
      brand_id: null,
      sub_brand: null,
      category_id: null,
      sub_category_id: null,
      size: null,
      color: null,
      gender_id: null,
      design_number: null,
      hs_code: null,
      hs_description: null,
      country_id: null,
      cycle_class_id: null,
    },
    serverData: null,
    isLoading: false,
    sub_categories: [],
  }),
  // created() {
  //   this.loadServerData();
  // },
  methods: {
    loadServerData() {
      // if (!this.serverData) {
      this.pageLoader(true);
      let payload = {
        tab_name: this.tabName,
      };

      if (this.actionItem) {
        payload.id = this.actionItem.id;
      }

      return ApiService.post("/fulfillment/skus/edit", payload)
        .then(({ data }) => {
          if (!this.serverData) {
            this.serverData = data;
          }

          Object.entries(data?.sku).forEach(([key, value]) => {
            this.formData[key] = value;
          });
        })
        .finally(() => {
          this.pageLoader(false);
        });
      // }
    },
    submit() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // }
      this.pageLoader(true);

      let url = `/fulfillment/skus/${this.actionItem ? `update` : `store`}`;

      const payload = {
        tab_name: this.tabName,
        id: this.actionItem.id,
        ...this.formData,
      };

      ApiService.post(url, payload)
        .then(({ data }) => {
          if (data.sku) {
            this.$store.commit(SET_ITEM_FOR_ACTION, data.sku);
          }
          const title = this.actionItem
            ? this.$t("updated")
            : this.$t("created");
          SwalService.successMessage({
            title: title,
            html: this.$t("submitted_successfully"),
          });
          this.refresher();
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    reset() {
      // this.$v.$reset();
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = null;
      });
    },
  },
  computed: {
    selectedCategory() {
      return this.formData.category_id;
    },
    filteredSubCategories() {
      return this.sub_categories;
    },
    // organization_idErrors() {
    //   return this.handleFormValidation("organization_id", this);
    // },
  },
  watch: {
    selectedCategory(newValue) {
      // console.log("selectedCategory", newValue);
      if (newValue) {
        this.isLoading = true;
        ApiService.post(`/skus/sub-categories`, {
          parent_id: this.formData.category_id,
        })
          .then(({ data }) => {
            if (data.sub_categories) {
              this.sub_categories = data.sub_categories;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
