<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Sync Inventory</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--begin::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row">
          <v-col cols="12">
            <v-select
              v-model="formData.organization_id"
              label="Organization"
              :items="$store.getters.getSKUSAmazonOrganizations"
              clearable
              outlined
              item-value="index"
              item-text="text"
              dense
              :error-messages="organizatinoErros"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            ></v-select>
          </v-col>
        </div>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-2 d-flex flex-grow-1 align-end justify-center">
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="onSubmit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";

export default {
  name: "AmazonIntegration",
  mixins: [validationMixin],
  props: ["submit", "items"],
  validations() {
    return {
      formData: {
        organization_id: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    formData: {
      awb: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    toggleModal() {
      this.reset();
      this.dialog = !this.dialog;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);

      let data = {
        ids: this.items.map((it) => it.id),
        organization_id: this.formData.organization_id,
      };

      ApiService.post(`/fulfillment/skus/sync_inventory`, data)
        .then(({ data }) => {
          try {
            Swal.fire({
              title: "Completed",
              html: data?.messages?.length
                ? `<div>${data?.messages
                    ?.map((mes) => `<div>${mes}</div>`)
                    .join("")}</div>`
                : "Record modified successfully",
              icon: "success",
            });
            this.toggleModal();
          } catch {
            Swal.fire({
              title: "Failed",
              html: "Opps..Something went wrong.",
              icon: "error",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "OK",
            });
          }

          this.refresher();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },

    reset() {
      this.formData = {
        organization_id: null,
      };
      this.$v.$reset();
    },
  },
  computed: {
    organizatinoErros: function () {
      return this.handleFormValidation("organization_id", this, "formData");
    },
  },
  mounted() {},
};
</script>
